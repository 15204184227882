<template>
  <div class="component">
    <NumericInput
      v-model="minSeedRate"
      name="minSeedRate"
      unit="K/m²"
    >
      <template #label>
        <IxRes>
          masterData.seed.min
        </IxRes>
        <IxRes>
          masterData.seed.optional
        </IxRes>
      </template>
    </NumericInput>

    <NumericInput
      v-model="maxSeedRate"
      name="maxSeedRate"
      unit="K/m²"
    >
      <template #label>
        <IxRes>
          masterData.seed.max
        </IxRes>
        <IxRes>
          masterData.seed.optional
        </IxRes>
      </template>
    </NumericInput>

    <DetailsMatrix
      v-model="detailsMatrix"
      :selected-product-id="selectedProductId"
    />
  </div>
</template>

<script>
import {mapFormFieldsFromValue, extractFormModelsFromProperties} from '@helpers/vue'

import DetailsMatrix from './DetailsMatrix'
import NumericInput from '@components/forms/NumericInput'

export default {
  components: {
    DetailsMatrix,
    NumericInput
  },
  props: {
    value: Object,
    selectedProductId: String
  },
  computed: {
    ...mapFormFieldsFromValue([
      'minSeedRate',
      'maxSeedRate',
      'maizeMatrix'
    ]),
    ...extractFormModelsFromProperties({
      detailsMatrix: ['maizeMatrix']
    })
  }
}
</script>

<style scoped>
.component {
  display: flex;
  flex-direction: column;
}
</style>
