<template>
  <VueSelectize
    v-model="cropUsageId"
    :name="`cropUsage_{{cropUsageId}}`"
    :fields="cropUsagesFieldConfig"
    :options="cropUsageIds"
    required allow-empty
  />
</template>

<script>
import {mapState} from 'vuex'
import {uniq, difference} from 'lodash'

import VueSelectize from '@components/VueSelectize'

export default {
  components: {
    VueSelectize
  },
  props: {
    value: null,
    componentIds: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    ...mapState('masterData', [
      'cropUsages'
    ]),
    ...mapState('i18n', {
      usageTranslations: state => state.translations['masterData.crops.usage']
    }),
    cropUsageId: {
      get () { return this.value },
      set (value) { this.$emit('input', value) }
    },
    cropUsageIds () {
      const unique = uniq(Object.keys(this.cropUsages))
      const others = difference(this.componentIds, [this.value])
      return difference(unique, others)
    },
    cropUsagesFieldConfig () {
      return {
        text: value => this.usageTranslations[`${value}_name`]
      }
    }
  }
}
</script>
