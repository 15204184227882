<template>
  <div class="details-matrix-component">
    <label>
      <IxRes>masterData.seed.seedingAmount</IxRes>
      <IxRes>masterData.seed.optional</IxRes>
    </label>

    <HelpBox>
      <IxRes>masterData.seed.matrixInfo</IxRes>
    </HelpBox>

    <div class="matrix">
      <div class="title">
        <IxRes>masterData.seed.waterAmount</IxRes>
      </div>
      <div class="quality">
        <IxRes>masterData.seed.soilQuality</IxRes>
      </div>

      <div class="wa-low">
        <strong><IxRes>masterData.seed.little</IxRes></strong>
      </div>
      <div class="wa-medium">
        <strong><IxRes>masterData.seed.medium</IxRes></strong>
      </div>
      <div class="wa-alot">
        <strong><IxRes>masterData.seed.much</IxRes></strong>
      </div>

      <div class="sq-bad">
        <strong><IxRes>masterData.seed.badQuality</IxRes></strong>
        <br>
        <i>BP &le; 30</i>
      </div>
      <div class="input1">
        <NumericInput
          :value="maizeMatrix[0]" hide-description
          name="matrixEntry_0"
          :rule="x => x >= 0"
          :reset-value="selectedProductMatrix[0]"
          @input="handleMatrixInput($event, 0)"
        />
      </div>
      <div class="input2">
        <NumericInput
          :value="maizeMatrix[1]" hide-description
          name="matrixEntry_1"
          :rule="x => x >= 0"
          :reset-value="selectedProductMatrix[1]"
          @input="handleMatrixInput($event, 1)"
        />
      </div>
      <div class="input3">
        <NumericInput
          :value="maizeMatrix[2]" hide-description
          name="matrixEntry_2"
          :rule="x => x >= 0"
          :reset-value="selectedProductMatrix[2]"
          @input="handleMatrixInput($event, 2)"
        />
      </div>
      <div class="sq-medium">
        <strong><IxRes>masterData.seed.mediumQuality</IxRes></strong>
        <br>
        <i>31 - 45 BP</i>
      </div>
      <div class="input4">
        <NumericInput
          :value="maizeMatrix[3]" hide-description
          name="matrixEntry_3"
          :rule="x => x >= 0"
          :reset-value="selectedProductMatrix[3]"
          @input="handleMatrixInput($event, 3)"
        />
      </div>
      <div class="input5">
        <NumericInput
          :value="maizeMatrix[4]" hide-description
          name="matrixEntry_4"
          :rule="x => x >= 0"
          :reset-value="selectedProductMatrix[4]"
          @input="handleMatrixInput($event, 4)"
        />
      </div>
      <div class="input6">
        <NumericInput
          :value="maizeMatrix[5]" hide-description
          name="matrixEntry_5"
          :rule="x => x >= 0"
          :reset-value="selectedProductMatrix[5]"
          @input="handleMatrixInput($event, 5)"
        />
      </div>
      <div class="sq-good">
        <strong><IxRes>masterData.seed.goodQuality</IxRes></strong>
        <br>
        <i>BP &ge; 46</i>
      </div>
      <div class="input7">
        <NumericInput
          :value="maizeMatrix[6]" hide-description
          name="matrixEntry_6"
          :rule="x => x >= 0"
          :reset-value="selectedProductMatrix[6]"
          @input="handleMatrixInput($event, 6)"
        />
      </div>
      <div class="input8">
        <NumericInput
          :value="maizeMatrix[7]" hide-description
          name="matrixEntry_7"
          :rule="x => x >= 0"
          :reset-value="selectedProductMatrix[7]"
          @input="handleMatrixInput($event, 7)"
        />
      </div>
      <div class="input9">
        <NumericInput
          :value="maizeMatrix[8]" hide-description
          name="matrixEntry_8"
          :rule="x => x >= 0"
          :reset-value="selectedProductMatrix[8]"
          @input="handleMatrixInput($event, 8)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'

import {mapFormFieldsFromValue} from '@helpers/vue'

import HelpBox from '@components/help/HelpBox'
import NumericInput from '@components/forms/NumericInput'

import StandardRulesMixin from '@components/forms/StandardRulesMixin'

export default {
  components: {
    HelpBox,
    NumericInput
  },
  mixins: [
    StandardRulesMixin
  ],
  props: {
    value: Object,
    selectedProductId: String
  },
  computed: {
    ...mapFormFieldsFromValue([
      'maizeMatrix'
    ]),
    ...mapGetters('masterData', [
      'products'
    ]),
    selectedProductMatrix () {
      if (this.selectedProductId) {
        const matrix = this.products[this.selectedProductId].maizeMatrix
        if (matrix && matrix.length === 9) {
          return this.products[this.selectedProductId].maizeMatrix
        }
      }
      return [0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
  },
  methods: {
    handleMatrixInput (value, index) {
      const newMatrix = this.maizeMatrix.slice()
      newMatrix.splice(index, 1, value) // splice works in-place, hence the slice and assignment
      this.maizeMatrix = newMatrix
    }
  }
}
</script>

<style scoped lang="scss">

.matrix {
  margin-top: 10px;
  display: grid;
  column-gap: 10px;
  row-gap: 10px;
  grid-template-columns: 50px 70px 1fr 1fr 1fr;
  grid-auto-rows: minmax(auto, auto);
  grid-template-areas:  ". . title title title"
                        ". . wa-low wa-medium wa-alot"
                        "quality sq-bad input1 input2 input3"
                        "quality sq-medium input4 input5 input6"
                        "quality sq-good input7 input8 input9"
}

.title {
  grid-area: title;
  align-self: center;
  justify-self: center;
  text-transform: uppercase;
}

.quality {
  grid-area: quality;
  transform: rotate(-90deg);
  align-self: center;
  justify-self: center;
  text-transform: uppercase;
}
.wa-low {
  grid-area: wa-low;
}
.wa-medium {
  grid-area: wa-medium;
}
.wa-alot {
  grid-area: wa-alot;
}
.sq-bad {
  grid-area: sq-bad;
}
.sq-medium {
  grid-area: sq-medium;
}
.sq-good {
  grid-area: sq-good;
}
.input1 {
  grid-area: input1;
}
.input2 {
  grid-area: input2;
}
.input3 {
  grid-area: input3;
}
.input4 {
  grid-area: input4;
}
.input5 {
  grid-area: input5;
}
.input6 {
  grid-area: input6;
}
.input7 {
  grid-area: input7;
}
.input8 {
  grid-area: input8;
}
.input9 {
  grid-area: input9;
}

</style>
