<template>
  <div class="component">
    <TextInput v-model="bsA_Culture" name="BSA">
      <template #label>
        <IxRes>
          masterData.seed.BSA_Culture
        </IxRes>
        <IxRes>
          masterData.seed.optional
        </IxRes>
      </template>
    </TextInput>

    <TextInput v-model="bsA_Culture_Short" name="BSA_Short">
      <template #label>
        <IxRes>
          masterData.seed.BSA_Culture_Short
        </IxRes>
        <IxRes>
          masterData.seed.optional
        </IxRes>
      </template>
    </TextInput>

    <NumericInput v-model="bsA_Number" name="BSA_Number">
      <template #label>
        <IxRes>
          masterData.seed.BSA_Number
        </IxRes>
        <IxRes>
          masterData.seed.optional
        </IxRes>
      </template>
    </NumericInput>
  </div>
</template>

<script>
import {mapFormFieldsFromValue} from '@helpers/vue'

import TextInput from '@components/forms/TextInput'
import NumericInput from '@components/forms/NumericInput'

export default {
  components: {
    TextInput,
    NumericInput
  },
  props: {
    value: Object
  },
  computed: {
    ...mapFormFieldsFromValue([
      'bsA_Culture',
      'bsA_Culture_Short',
      'bsA_Number'
    ])
  }
}
</script>

<style lang="scss" scoped>
.component {
  display: flex;
  flex-direction: column;
}
</style>
