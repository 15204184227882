<template>
  <div class="mixture-form">
    <div class="grid-container">
      <IxRes>masterData.seeds.cropUsage</IxRes>
      <IxRes>masterData.seeds.percentage</IxRes>
    </div>
    <div
      v-for="component in components"
      :key="component.cropUsageId"
      class="grid-container"
    >
      <UniqueCropUsageSelector
        v-model="component.cropUsageId"
        :component-ids="components.map(x => x.cropUsageId)"
      />
      <NumericInput
        v-model="component.percentage"
        percent
        hide-description
        :rule="between(0, 100)"
        :name="`percentage_{{component.cropUsageId}}`"
      />
      <IxButton
        class="delete-button" large
        icon="trash"
        @click="remove(component)"
      />
    </div>
    <IxButton add @click="add" />
  </div>
</template>

<script>
import {uniq} from 'lodash'
import {mapState} from 'vuex'

import NumericInput from '@components/forms/NumericInput'
import IxButton from '@components/IxButton'

import StandardRulesMixin from '@components/forms/StandardRulesMixin'

import UniqueCropUsageSelector from './UniqueCropUsageSelector'
function makeEmptyRow () {
  return {cropUsageId: null, percentage: 0}
}

export default {
  components: {
    UniqueCropUsageSelector,
    NumericInput,
    IxButton
  },
  mixins: [
    StandardRulesMixin
  ],
  props: {
    value: Array
  },
  computed: {
    ...mapState('masterData', [
      'cropUsages'
    ]),
    ...mapState('i18n', {
      usageTranslations: state => state.translations['masterData.crops.usage']
    }),
    components () {
      return this.value || []
    },
    cropUsageIds () {
      return uniq(Object.keys(this.cropUsages))
    },
    cropUsagesFieldConfig () {
      return {
        text: value => this.usageTranslations[`${value}_name`]
      }
    }
  },
  methods: {
    add () {
      this.$emit('input', this.components.concat(makeEmptyRow()))
    },
    remove (componentToRemove) {
      this.$emit('input', this.components.filter(component => component !== componentToRemove))
    }
  }
}
</script>

<style lang="scss">
  .mixture-form {
    min-height: 500px;

    .grid-container {
      display: grid;
      grid-gap: 2px;
      grid: auto / 3fr 1fr min-content;
    }
    .delete-button {
      height: 34px;
    }
  }
</style>
