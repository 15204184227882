<template>
  <div class="component">
    <TextInput
      v-model="name" name="name"
      placeholder="Name"
      required
    >
      <template #label>
        <IxRes>masterData.productName</IxRes>
      </template>
    </TextInput>

    <VueSelectize
      v-model="cropId"
      name="cropId"
      required
      allow-empty
      :fields="cropsFieldConfig"
      :options="cropIds"
    >
      <template #label>
        <IxRes>masterData.seed.cropSelector</IxRes>
      </template>
    </VueSelectize>

    <TextInput
      v-model="note"
      name="comment"
      multiline
      :rows="3"
    >
      <template #label>
        <IxRes>
          masterData.seed.comment
        </IxRes>
        <IxRes>
          masterData.seed.optional
        </IxRes>
      </template>
    </TextInput>

    <TextInput v-model="quality" name="quality">
      <template #label>
        <IxRes>
          masterData.seed.quality
        </IxRes>
        <IxRes>
          masterData.seed.optional
        </IxRes>
      </template>
    </TextInput>
  </div>
</template>

<script>

import {mapState} from 'vuex'

import {mapFormFieldsFromValue} from '@helpers/vue'

import StandardRulesMixin from '@components/forms/StandardRulesMixin'

import VueSelectize from '@components/VueSelectize'
import TextInput from '@components/forms/TextInput'

export default {
  components: {
    VueSelectize,
    TextInput
  },
  mixins: [
    StandardRulesMixin
  ],
  props: {
    value: Object
  },
  computed: {
    ...mapState('i18n', {
      masterDataTranslation: state => state.translations['masterData.seed']
    }),
    ...mapState('masterData', [
      'productAlgorithms',
      'crops'
    ]),
    ...mapState('i18n', {
      cropTranslations: state => state.translations['masterData.crops.crop']
    }),
    ...mapFormFieldsFromValue([
      'name',
      'cropId',
      'note',
      'quality'
    ]),
    cropIds () {
      return Object.keys(this.crops).filter(c => !this.crops[c].isArchived)
    },
    cropsFieldConfig () {
      return {
        text: value => this.cropTranslations[`${value}_name`]
      }
    },
    algorithmOptions () {
      return Object.values(this.productAlgorithms).filter(x => x.name.includes('Seed'))
    }
  }
}
</script>

<style lang="scss" scoped>
.component {
  display: flex;
  flex-direction: column;
}
</style>
